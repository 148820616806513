.chat-container {
  display: flex;
  flex-direction: column;
  height: 90vh;
  width: 80%;
  margin: auto;
  border: 1px solid #ccc;
  background-color: #f9f9f9;
}

.messages {
  font-family: Arial, Helvetica, sans-serif;
  flex-grow: 1;
  overflow-y: auto;
  padding: 10px;
}

.message-row {
  display: flex;
  align-items: center;
  justify-content: flex-end; /* Ensures alignment to the edge */
  margin-bottom: 10px;
}

.message {
  max-width: 80%;
  flex-grow: 1;
  box-shadow: 1px 1px 3px rgba(0,0,0,0.2); /* Optional shadow for better visibility */
}

.user .message {
  margin-left: 30px; /* Increased space between the icon and message box for the user */
  background-color: #F6F6F6;
}

.bot .message {
  margin-right: 20px; /* Increased space between the icon and message box for the bot */
  background-color: #EDE6F2;
}

.user, .bot {
  align-items: center;
}

.icon {
  flex-shrink: 0;
  box-sizing: content-box; /* Includes padding and border in the element's total width and height */
  padding: 5px; /* Example padding */
}

.bot {
  justify-content: flex-start; /* Align bot messages to the left */
}

.user {
  justify-content: flex-end; /* Align user messages to the right */
}
